<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 row">
            <div class="col-12">
                <div class="card">
                    <!-- Card header -->
                    <div class="card-header border-bottom">
                        <div class="row d-flex align-items-center">
                            <div class="col-6">
                                <h5 class="mb-0">Ingatlan Módosítása</h5>
                            </div>
                            <div class="col-6 text-end">
                                <material-button class="float-right btn btm-sm"
                                    @click="this.$router.push({ name: 'RealEstates List' })">
                                    Vissza
                                </material-button>
                            </div>
                        </div>
                    </div>
                    <!--Card body-->
                    <div class="card-body">
                        <form>
                            <div class="row">
                                <div class="col-5">
                                    <material-input id="name" v-model:value="realestate.name" label="Név"
                                        name="name" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.name"></validation-error>
                                </div>

                                <div class="col-5">
                                    <material-input id="lot_number" v-model:value="realestate.lot_number" label="Helyrajzi Szám"
                                        name="lot_number" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.lot_number"></validation-error>
                                </div>

                                <div class="col-5">
                                    <label class="form-label mb-0 ms-0">Country</label>
                                    <select id="choices-countries" v-model="realestate.country_code" name="choices-countries" class="form-control">
                                        <option v-for="country in getcountryList" :key="country.code" :value="country.code">{{ country.name }}</option>
                                    </select>
                                </div>

                                <div class="col-5">
                                    <material-input id="zip" v-model:value="realestate.zip" label="Irányítószám"
                                        name="zip" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.zip"></validation-error>
                                </div>

                                <div class="col-5">
                                    <material-input id="city" v-model:value="realestate.city" label="Város"
                                        name="city" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.city"></validation-error>
                                </div>

                                <div class="col-5">
                                    <material-input id="address" v-model:value="realestate.address" label="Cím"
                                        name="address" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.address"></validation-error>
                                </div>

                                <div class="col-5">
                                    <label class="form-label mb-0 ms-0">Tulajdonos</label>
                                    <select id="choices-owners" v-model.number="realestate.owner_id" name="choices-owners" class="form-control">
                                        <option v-for="owner in getownerList" :key="owner.id" :value="owner.id">{{ owner.name }}</option>
                                    </select>
                                </div>

                                <div class="col-5">
                                    <material-input id="ownership" v-model:value="realestate.ownership" label="Tulajdoni hányad"
                                        name="ownership" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.ownership"></validation-error>
                                </div>

                                <div class="col-5">
                                    <material-input id="deed_size" v-model:value="realestate.deed_size" label="Tulajdoni lap szerinti alapterület (m2)"
                                    name="deed_size" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.deed_size"></validation-error>
                                </div>

                                <div class="col-5">
                                    <material-input id="gross_size" v-model:value="realestate.gross_size" label="Ingatlan bruttó alapterülete (m2)"
                                    name="gross_size" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.gross_size"></validation-error>
                                </div>

                                <div class="col-5">
                                    <material-input id="building_nr" v-model:value="realestate.building_nr" label="Épületek száma (db)"
                                        name="building_nr" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.building_nr"></validation-error>
                                </div>

                                <div class="col-5">
                                    <label class="form-label mb-0 ms-0">Ingatlan funkció</label>
                                    <select id="choices-sfunctions" v-model.number="realestate.realestate_functions_id" name="choices-sfunctions" class="form-control">
                                        <option v-for="sfunction in getfunctionList" :key="sfunction.id" :value="sfunction.id">{{ sfunction.name }}</option>
                                    </select>
                                </div>

                                <div class="col-5">
                                    <label class="form-label mb-0 ms-0">Ingatlan kezelési forma</label>
                                    <select id="choices-handlings" v-model.number="realestate.realestate_handlings_id" name="choices-handlings" class="form-control">
                                        <option v-for="handling in gethandlingList" :key="handling.id" :value="handling.id">{{ handling.name }}</option>
                                    </select>
                                </div>

                                <div class="col-5">
                                    <material-input id="property_manager_name" v-model:value="realestate.property_manager_name" label="Ingatlankezelő neve"
                                        name="property_manager_name" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.property_manager_name"></validation-error>
                                </div>

                                <div class="col-5">
                                    <material-input id="property_manager_phone" v-model:value="realestate.property_manager_phone" label="Ingatlankezelő telefonszáma"
                                        name="property_manager_phone" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.property_manager_phone"></validation-error>
                                </div>

                                <div class="col-5">
                                    <material-input id="property_manager_email" v-model:value="realestate.property_manager_email" label="Ingatlankezelő e-mail címe"
                                        name="property_manager_email" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.property_manager_email"></validation-error>
                                </div>
                                
                            </div>

                            <div class="row mt-1 mb-5 d-flex align-items-center">
                                <div class="col-2 text-end mt-5">
                                    <material-button class="float-right btn btm-sm" @click.prevent="handleEdit">Hozzáadás</material-button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Choices from 'choices.js';
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/formMixin.js";
import showSwal from "../../../mixins/showSwal";
import _ from "lodash"

export default {
    name: 'EditRealEstate',
    components: {
        MaterialButton,
        MaterialInput,
        ValidationError,
    },
    mixins: [formMixin],
    setup() {
        return {
            color: '#33e8f5'
        }
    },
    data() {
        return {
            realestate: {}
        }
    },
    computed: {
        getcountryList() {
            return this.$store.getters["countries/getCountriesData"];
        },
        getownerList() {
            return this.$store.getters["owners/getOwnersData"];
        },
        getfunctionList() {
            return this.$store.getters["sfunctions/getFunctionsData"];
        },
        gethandlingList() {
            return this.$store.getters["handlings/getHandlingsData"];
        },
        computedDeedSize: {
            get() {
                return String(this.realestate.deed_size);
            }
        },
    },
    async mounted() {
        await this.$store.dispatch("countries/getCountries");
        await this.$store.dispatch("owners/getOwners");
        await this.$store.dispatch("sfunctions/getFunctions");
        await this.$store.dispatch("handlings/getHandlings");
    
        try {
            await this.$store.dispatch("realestates/getRealEstate", this.$route.params.id);
            this.realestate = _.omit(this.$store.getters['realestates/getRealEstate'], 'links');
        } catch (error) {
            showSwal.methods.showSwal({
                type: "error",
                message: `Valami hiba történt!`,
                width: 500
            });
        }
        if (document.getElementById("choices-countries")) {
            var countries = document.getElementById("choices-countries");
            this.countries = new Choices(countries);
            this.countries.setChoiceByValue(this.realestate.country_code);
        }

        if (document.getElementById("choices-owners")) {
            var owners = document.getElementById("choices-owners");
            this.owners = new Choices(owners);
            this.owners.setChoiceByValue(String(this.realestate.owner_id));
        }

        if (document.getElementById("choices-sfunctions")) {
            var sfunctions = document.getElementById("choices-sfunctions");
            this.sfunctions = new Choices(sfunctions);
            this.sfunctions.setChoiceByValue(String(this.realestate.realestate_functions_id));
        }

        if (document.getElementById("choices-handlings")) {
            var handlings = document.getElementById("choices-handlings");
            this.handlings = new Choices(handlings);
            this.handlings.setChoiceByValue(String(this.realestate.realestate_handlings_id));
        }
    },
    methods: {
        async handleEdit() {
            this.resetApiValidation();
            try {
                this.realestate.deed_size = parseFloat(this.realestate.deed_size);
                this.realestate.gross_size = parseFloat(this.realestate.gross_size);
                await this.$store.dispatch("realestates/editRealEstate", this.realestate);
                showSwal.methods.showSwal({
                    type: "success",
                    message: "Ingatlan sikeresen módosítva!",
                    width: 500
                });
                this.$router.push({ name: "RealEstates List" })
            } catch (error) {
                if (error.response.data.errors) {
                    this.setApiValidation(error.response.data.errors);
                }
                showSwal.methods.showSwal({
                    type: "error",
                    message: "Valami hiba történt!",
                    width: 500
                });

            }
        }
    }
}
</script>