<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 row">
            <div class="col-12">
                <div class="card">
                    <!-- Card header -->
                    <div class="card-header border-bottom">
                        <div class="row d-flex align-items-center">
                            <div class="col-6">
                                <h5 class="mb-0">Tulajdonos Hozzáadása</h5>
                            </div> 
                            <div class="col-6 text-end">
                                <material-button class="float-right btn btm-sm"
                                    @click="this.$router.push({ name: 'Owners List' })">
                                    Vissza
                                </material-button>
                            </div>
                        </div>
                    </div>
                    <!--Card body-->
                    <div class="card-body">
                        <form>
                            <div class="row">
                                <div class="col-5">
                                    <material-input id="name" v-model:value="owner.name" label="Cégnév/Név"
                                        name="name" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.name"></validation-error>
                                </div>
                                
                                <div class="col-5">
                                    <material-input id="vat_number" v-model:value="owner.vat_number" label="Adószám"
                                        name="vat_number" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.vat_number"></validation-error>
                                </div>

                                <div class="col-10 border border-secondary rounded m-2 p-2">
                                    <div v-for="(data, index) in ownersdata" :key="index">
                                        <div class="row">
                                            <div class="col-5">
                                                <material-input :id="`owner${index + 1}`" v-model:value="owner['owner' + (index + 1)]" :label="`${index + 1}. Tulajdonos neve`"
                                                    :name="`owner${index + 1}`"  variant="static" @input="updateOwner($event.target.value, index)"></material-input>
                                                    <validation-error :errors="apiValidationErrors['owner' + (index + 1)]"></validation-error>
                                            </div>
                                            <div class="col-5">
                                                <material-input :id="`ownership${index + 1}`" v-model:value="owner['ownership' + (index + 1)]" label="Tulajdoni hányad"
                                                    :name="`ownership${index + 1}`" variant="static"  @input="updateOwnership($event.target.value, index)"></material-input>
                                                    <validation-error :errors="apiValidationErrors['ownership' + (index + 1)]"></validation-error>
                                            </div>
                                            <div class="col-2 d-table">
                                                <span aria-hidden="true" class="text-xs font-weight-bold d-table-cell align-middle">
                                                    <i v-show="index === 0 && ownersdata.length < 3" class="fas fa-plus-circle" @click="addMore()"> Add fields</i>
                                                    <i v-show="index != 0 && ownersdata.length === (index + 1)" class="fas fa-minus-circle" @click="remove(index)"> Remove</i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-5">
                                    <material-input id="contact" v-model:value="owner.contact" label="Kapcsolattartó"
                                        name="contact" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.contact"></validation-error>
                                </div>

                                <div class="col-5">
                                    <material-input id="phone_number" v-model:value="owner.phone_number" label="Telefonszám"
                                        name="phone_number" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.phone_number"></validation-error>
                                </div>

                                <div class="col-5">
                                    <material-input id="address" v-model:value="owner.address" label="Levelezési cím"
                                        name="address" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.address"></validation-error>
                                </div>

                                <div class="col-5">
                                    <material-input id="email" v-model:value="owner.email" label="Kapcsolattartási Email"
                                        name="email" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.email"></validation-error>
                                </div>

                                <div class="col-10">
                                    <material-input id="account_number" v-model:value="owner.account_number" label="Számlaszám"
                                        name="account_number" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.account_number"></validation-error>
                                </div>
                                
                            </div>

                            <div class="row mt-5 mb-5 d-flex align-items-center">

                                <div class="col-2 text-end mt-5">
                                    <material-button class="float-right btn btm-sm" @click.prevent="handleAdd">Hozzáadás</material-button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/formMixin.js";
import showSwal from "../../../mixins/showSwal";

export default {
    name: 'addOwner',
    components: {
        MaterialButton,
        MaterialInput,
        ValidationError
    },
    mixins: [formMixin],
    setup() {
        return {
            color: '#33e8f5'
        }
    },
    data() {
        return {
            owner: {
            },
            ownersdata: [
                {
                    ownerName: "",
                    ownership: "",
                },
            ],
        }
    },
    computed: {
        ownerName(index) {
            console.log(index);
            if (typeof(index) != 'undefined') {
                //let newName = String('owner' + index + 1);
                return this.apiValidationErrors['owner' + (index + 1)];
            }
            return null;
        },
    },
    methods: {
        async handleAdd() {
            this.resetApiValidation();
            try {
                console.log(this.ownersdata);
                await this.$store.dispatch("owners/addOwner", this.owner);
                showSwal.methods.showSwal({
                    type: "success",
                    message: "Tulajdonos sikeresen hozzáadva!",
                    width: 500
                });
                this.$router.push({ name: "Owners List" })
            } catch (error) {
                if (error.response.data.errors) {
                    this.setApiValidation(error.response.data.errors);
                }
                showSwal.methods.showSwal({
                    type: "error",
                    message: "Valami hiba történt!",
                    width: 500
                });

            }
        },
        addMore() {
            this.ownersdata.push({
                ownerName: "",
                ownership: "",
            });
        },
        remove(index) {
            this.ownersdata.splice(index, 1);
        },
        updateOwner: function(value, index) {
            this.owner['owner' + (index + 1)] = value;
            this.$emit('input', value);
        },
        updateOwnership: function(value, index) {
            this.owner['ownership' + (index + 1)] = value;
            this.$emit('input', value);
        }
    }
}
</script>