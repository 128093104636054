import { createRouter, createWebHistory } from "vue-router";
import Default from "../views/dashboards/Default.vue";
import Sales from "../views/dashboards/Sales.vue";
import Notifications from "../views/pages/Notifications.vue";
import NewUser from "../views/pages/users/NewUser.vue";
import Settings from "../views/pages/account/Settings.vue";
import Billing from "../views/pages/account/Billing.vue";
import Invoice from "../views/pages/account/Invoice.vue";
import Basic from "../views/auth/signin/Basic.vue";
import Cover from "../views/auth/signin/Cover.vue";
import Illustration from "../views/auth/signin/Illustration.vue";
import ResetCover from "../views/auth/reset/Cover.vue";
import SignupCover from "../views/auth/signup/Cover.vue";
import Login from "../views/Login.vue";
import Signup from "../views/Signup.vue";
import PasswordForgot from "../views/PasswordForgot.vue";
import PasswordReset from "../views/PasswordReset.vue";
import UserProfile from "../views/examples-api/user-profile/UserProfile.vue";
import RolesList from "../views/examples-api/roles/RolesList.vue";
import EditRole from "../views/examples-api/roles/EditRole.vue";
import AddRole from "../views/examples-api/roles/AddRole.vue";
import UsersList from "../views/examples-api/users/UsersList.vue";
import EditUser from "../views/examples-api/users/edit-user/EditUser.vue";
import AddUser from "../views/examples-api/users/add-user/AddUser.vue";
import RealEstatesList from "../views/pages/realestates/RealEstatesList.vue";
import AddRealEstate from "../views/pages/realestates/AddRealEstate.vue";
import EditRealEstate from "../views/pages/realestates/EditRealEstate.vue";
import AreasList from "../views/pages/areas/AreasList.vue";
import AddAreas from "../views/pages/areas/AddArea.vue";
import EditAreas from "../views/pages/areas/EditArea.vue";
import OwnersList from "../views/pages/owners/OwnerList.vue";
import AddOwners from "../views/pages/owners/AddOwner.vue";
import EditOwners from "../views/pages/owners/EditOwner.vue";
import RentersList from "../views/pages/renters/RenterList.vue";
import AddRenters from "../views/pages/renters/AddRenter.vue";
import EditRenters from "../views/pages/renters/EditRenter.vue";
import MetersList from "../views/pages/meters/MeterList.vue";
import AddMeters from "../views/pages/meters/AddMeter.vue";
import EditMeters from "../views/pages/meters/EditMeter.vue";
import InvoiceList from "../views/pages/incoming-invoices/InvoiceList.vue";
import AddInInvoice from "../views/pages/incoming-invoices/AddInvoice.vue";
import EditInInvoice from "../views/pages/incoming-invoices/EditInvoice.vue";
import SubMetersList from "../views/pages/submeters/SubMeterList.vue";
import AddSubMeters from "../views/pages/submeters/AddSubMeter.vue";
import EditSubMeters from "../views/pages/submeters/EditSubMeter.vue";
import Calculation from "../views/pages/calculation/Calculation.vue";
import AddUtility from "../views/pages/unetwork/AddUtility.vue";
import EditUtility from "../views/pages/unetwork/EditUtility.vue";
import UtilitiesList from "../views/pages/unetwork/UtilityList.vue";
import store from "@/store";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/login",
  },
  {
    path: "/dashboards/dashboard-default",
    name: "Default",
    component: Default,
  },
  {
    path: "/dashboards/sales",
    name: "Sales",
    component: Sales,
  },
  {
    path: "/pages/notifications",
    name: "Notifications",
    component: Notifications,
  },
  {
    path: "/pages/users/new-user",
    name: "New User",
    component: NewUser,
  },
  {
    path: "/pages/account/settings",
    name: "Settings",
    component: Settings,
  },
  {
    path: "/pages/account/billing",
    name: "Billing",
    component: Billing,
  },
  {
    path: "/pages/account/invoice",
    name: "Invoice",
    component: Invoice,
  },
  {
    path: "/authentication/signin/basic",
    name: "Signin Basic",
    component: Basic,
  },
  {
    path: "/authentication/signin/cover",
    name: "Signin Cover",
    component: Cover,
  },
  {
    path: "/authentication/signin/illustration",
    name: "Signin Illustration",
    component: Illustration,
  },
  {
    path: "/authentication/reset/cover",
    name: "Reset Cover",
    component: ResetCover,
  },
  {
    path: "/authentication/signup/cover",
    name: "Signup Cover",
    component: SignupCover,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      authRequired: false,
      permission: null
    }
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
    meta: {
      authRequired: false,
      permission: null
    }
  },
  {
    path: "/password-forgot",
    name: "PasswordForgot",
    component: PasswordForgot,
    meta: {
      authRequired: false,
      permission: null
    }
  },
  {
    path: "/password-reset",
    name: "PasswordReset",
    component: PasswordReset,
    meta: {
      authRequired: false,
      permission: null
    }
  },
  {
    path: "/examples/user-profile",
    name: "User Profile",
    component: UserProfile,
    meta: {
      authRequired: true,
      permission: null
    }
  },
  {
    path: "/pages/settings/roles/roles-list",
    name: "Roles List",
    component: RolesList,
    meta: {
      authRequired: true,
      permission: ['admin']
    }
  },
  {
    path: "/pages/settings/roles/add-role",
    name: "Add Role",
    component: AddRole,
    meta: {
      authRequired: true,
      permission: ['admin']
    }
  },
  {
    path: "/pages/settings/roles/edit-role",
    name: "Edit Role",
    component: EditRole,
    meta: {
      authRequired: true,
      permission: ['admin']
    }
  },
  {
    path: "/pages/settings/users/users-list",
    name: "Users List",
    component: UsersList,
    meta: {
      authRequired: true,
      permission: ['admin']
    }
  },
  {
    path: "/pages/settings/users/add-user",
    name: "Add User",
    component: AddUser,
    meta: {
      authRequired: true,
      permission: ['admin']
    }
  },
  {
    path: "/pages/settings/users/edit-user",
    name: "Edit User",
    component: EditUser,
    meta: {
      authRequired: true,
      permission: ['admin']
    }
  },
  {
    path: "/pages/realestates/realestates-list",
    name: "RealEstates List",
    component: RealEstatesList,
    meta: {
      authRequired: true,
      permission: ['admin', 'creator']
    }
  },
  {
    path: "/pages/realestates/add-realestate",
    name: "Add RealEstate",
    component: AddRealEstate,
    meta: {
      authRequired: true,
      permission: ['admin', 'creator']
    }
  },
  {
    path: "/pages/realestates/edit-realestate/:id",
    name: "Edit RealEstate",
    component: EditRealEstate,
    meta: {
      authRequired: true,
      permission: ['admin', 'creator']
    }
  },
  {
    path: "/pages/unetwork/utility-list",
    name: "Utilities List",
    component: UtilitiesList,
    meta: {
      authRequired: true,
      permission: ['admin', 'creator']
    }
  },
  {
    path: "/pages/unetwork/add-utility",
    name: "Add Utility",
    component: AddUtility,
    meta: {
      authRequired: true,
      permission: ['admin', 'creator']
    }
  },
  {
    path: "/pages/unetwork/edit-utility/:id",
    name: "Edit Utility",
    component: EditUtility,
    meta: {
      authRequired: true,
      permission: ['admin', 'creator']
    }
  },
  {
    path: "/pages/owners/owners-list",
    name: "Owners List",
    component: OwnersList,
    meta: {
      authRequired: true,
      permission: ['admin', 'creator']
    }
  },
  {
    path: "/pages/owners/add-owners",
    name: "Add Owner",
    component: AddOwners,
    meta: {
      authRequired: true,
      permission: ['admin', 'creator']
    }
  },
  {
    path: "/pages/owners/edit-owners/:id",
    name: "Edit Owner",
    component: EditOwners,
    meta: {
      authRequired: true,
      permission: ['admin', 'creator']
    }
  },
  {
    path: "/pages/renters/renters-list",
    name: "Renters List",
    component: RentersList,
    meta: {
      authRequired: true,
      permission: ['admin', 'creator']
    }
  },
  {
    path: "/pages/renters/add-renters",
    name: "Add Renter",
    component: AddRenters,
    meta: {
      authRequired: true,
      permission: ['admin', 'creator']
    } 
  },
  {
    path: "/pages/renters/edit-renters/:id",
    name: "Edit Renter",
    component: EditRenters,
    meta: {
      authRequired: true,
      permission: ['admin', 'creator']
    }
  },
  {
    path: "/pages/meters/meters-list",
    name: "Meters List",
    component: MetersList,
    meta: {
      authRequired: true,
      permission: ['admin', 'creator']
    }
  },
  {
    path: "/pages/meters/add-meters",
    name: "Add Meter",
    component: AddMeters,
    meta: {
      authRequired: true,
      permission: ['admin', 'creator']
    }
  },
  {
    path: "/pages/meters/edit-meters/:id",
    name: "Edit Meter",
    component: EditMeters,
    meta: {
      authRequired: true,
      permission: ['admin', 'creator']
    }
  },
  {
    path: "/pages/submeters/submeters-list",
    name: "SubMeters List",
    component: SubMetersList,
    meta: {
      authRequired: true,
      permission: ['admin', 'creator']
    }
  },
  {
    path: "/pages/submeters/add-submeters",
    name: "Add SubMeter",
    component: AddSubMeters,
    meta: {
      authRequired: true,
      permission: ['admin', 'creator']
    }
  },
  {
    path: "/pages/submeters/edit-submeters/:id",
    name: "Edit SubMeter",
    component: EditSubMeters,
    meta: {
      authRequired: true,
      permission: ['admin', 'creator']
    }
  },
  {
    path: "/pages/calculation",
    name: "Calculation",
    component: Calculation,
    meta: {
      authRequired: true,
      permission: ['admin', 'creator']
    }
  },
  {
    path: "/pages/incoming-invoices/invoices-list",
    name: "InInvoice List",
    component: InvoiceList,
    meta: {
      authRequired: true,
      permission: ['admin', 'creator']
    }
  },
  {
    path: "/pages/incoming-invoices/add-invoice",
    name: "Add InInvoice",
    component: AddInInvoice,
    meta: {
      authRequired: true,
      permission: ['admin', 'creator']
    }
  },
  {
    path: "/pages/incoming-invoices/edit-invoice",
    name: "Edit InInvoice",
    component: EditInInvoice,
    meta: {
      authRequired: true,
      permission: ['admin', 'creator']
    }
  },
  {
    path: "/pages/areas/areas-list",
    name: "Areas List",
    component: AreasList,
    meta: {
      authRequired: true,
      permission: ['admin', 'creator']
    }
  },
  {
    path: "/pages/areas/add-areas",
    name: "Add Area",
    component: AddAreas,
    meta: {
      authRequired: true,
      permission: ['admin', 'creator']
    }
  },

  {
    path: "/pages/areas/edit-areas/:id",
    name: "Edit Area",
    component: EditAreas,
    meta: {
      authRequired: true,
      permission: ['admin', 'creator']
    }
  },


];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

//eslint-disable-next-line no-unused-vars
router.beforeEach(async (to, from, next) => {

  let { authRequired, permission } = to.meta;

  const isLoggedIn = store.getters['auth/isLoggedIn'];
  
  if (authRequired) {

    //if trying to access a page that requires authentication but not logged in, redirect to login page
    if (!isLoggedIn) {
      return next({ name: 'Login', query: { returnUrl: to.path } });
    }

    //if trying to access a page that requires permission, redirect to dashboard
    if (permission) {
      try {
        await store.dispatch('profile/getProfile');
        const userPermission = (store.getters['profile/getUserProfile']).roles[0].name;
        
        if (!permission.includes(userPermission)) {
          return next({ name: 'Default', query: { returnUrl: to.path } });
        }
      }catch(error){
        try{
          store.dispatch('auth/logout');
        }finally{
          // eslint-disable-next-line no-unsafe-finally
          return next({name: "Login"});
        }
      }
    }

  }
  next();
})

export default router;
