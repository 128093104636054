<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 row">
            <div class="col-12">
                <div class="card">
                    <!-- Card header -->
                    <div class="card-header border-bottom">
                        <div class="row d-flex align-items-center">
                            <div class="col-6">
                                <h5 class="mb-0">Főmérő regisztráció</h5>
                            </div>
                            <div class="col-6 text-end">
                                <material-button class="float-right btn btm-sm"
                                    @click="this.$router.push({ name: 'Meters List' })">
                                    Vissza
                                </material-button>
                            </div>
                        </div> 
                    </div>
                    <!--Card body-->
                    <div class="card-body">
                        <form>
                            <div class="row">
                                <div class="col-5">
                                    <label class="form-label mb-0 ms-0">POD</label>
                                    <select id="choices-realestate" v-model.number="meter.pod" name="choices-realestate" class="form-control" @change="setRealEstate($event.target.value)">
                                        <option v-for="utility in UtilitiesList" :key="utility.id" :value="utility.id">{{ utility.pod }}</option>
                                    </select>
                                    <validation-error :errors="apiValidationErrors.pod"></validation-error>
                                </div>

                                <div class="col-5">
                                    <material-input id="realestate_name" v-model:value="meter.realestate_name" label="Hely neve"
                                        name="realestate_name" variant="static"></material-input>
                                </div>

                                <div class="col-5">
                                    <material-input id="realestate_address" v-model:value="meter.realestate_address" label="Hely címe"
                                        name="realestate_address" variant="static"></material-input>
                                </div>
                            </div>

                            <br />

                            <div class="row p-4 bg-light">
                                <div class="col-5">
                                    <material-input id="product_number" v-model:value="meter.product_number" label="Gyáriszám"
                                        name="product_number" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.product_number"></validation-error>
                                </div>

                                <div class="col-5">
                                    <label class="form-label mb-0 ms-0">Mérési egység</label>
                                    <select id="choices-munits" v-model="meter.measurement_units_id" name="choices-munits" class="form-control">
                                        <option v-for="unit in getMeasurementUnitsList" :key="unit.id" :value="unit.id">{{ unit.name }}</option>
                                    </select>
                                </div> 

                                <div class="col-5">
                                    <label class="form-label mb-0 ms-0">Beüzemelés időpontja</label>
                                    <VueDatePicker id="selected-date" v-model="currentDate" :teleport="true" :format-locale="hu" :locale="hu" :format="format" :enable-time-picker="false" select-text="Választ" cancel-text="Bezár"/>
                                </div>

                                <div class="col-5">
                                    <label class="form-label mb-0 ms-0">Pozíció</label>
                                    <select id="choices-position" v-model="meter.position" name="choices-position" class="form-control">
                                        <option v-for="area in AreaList" :key="area.id" :value="area.id">{{ area.area_nr }}</option>
                                    </select>
                                </div>
                                
                                <div class="col-5">
                                    <label>Heylségek hozzáadása</label>
                                    <MultiSelect v-model:selectValue="meter.area_ids" :options="areas" select-all="true" select-all-label="Mindet kijelöl" cleaner="false" @show="() => setChoice()" />
                                    <validation-error :errors="apiValidationErrors.area_ids"></validation-error>
                                </div>
                            </div>

                            <div class="row mt-2 mb-5 d-flex align-items-center">
                                <div class="col-2 text-end mt-5">
                                    <material-button class="float-right btn btm-sm" @click.prevent="handleAdd">Hozzáadás</material-button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Choices from 'choices.js';
import { ref } from 'vue';
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/formMixin.js";
import showSwal from "../../../mixins/showSwal";
import MultiSelect from "@/components/MultiSelect.vue";
import VueDatePicker from '@vuepic/vue-datepicker';
import { hu } from 'date-fns/locale';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
    name: 'AddMeter',
    components: {
        MaterialButton,
        MaterialInput,
        VueDatePicker,
        MultiSelect,
        ValidationError
    },
    mixins: [formMixin],
    setup() {
        const currentDate = ref(new Date());
        const areas = ref([]);
        const format = (date) => {
            const day = date.getDate() < 9 ? "0" + date.getDate() : date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();

            return `${year}-${month}-${day}`;
        };
        return {
            color: '#33e8f5',
            currentDate,
            hu,
            format,
            areas,
        }
    },
    data() {
        return {
            meter: {},
            date: null,
        }
    },
    computed: {
        getRealEstateList() {
            return this.$store.getters["realestates/getRealEstatesData"];
        },
        getMeasurementUnitsList() {
            return this.$store.getters["munits/getMunitsData"];
        },
        UtilitiesList() {
            return this.$store.getters["unetworks/getUnetworksData"];
        },
        AreaList() {
            return this.$store.getters["areas/getAreasData"];
        },
        watch: {
            AreaList: function () {
                this.setChoice();
            },
        },
    },
    async mounted() {
        await this.$store.dispatch("realestates/getRealEstates");
        await this.$store.dispatch("unetworks/getUnetworks");
        await this.$store.dispatch("areas/getAreas");
        await this.$store.dispatch("munits/getMunits");

        if (document.getElementById("choices-realestate")) {
            var realestate = document.getElementById("choices-realestate");
            this.realestate = new Choices(realestate);
            const selectedUtility = this.UtilitiesList.filter(value => {
                return value.id === this.realestate.getValue(true)
            });
            this.currentRealEstate = this.getRealEstateList.filter(value => {
                return value.id == selectedUtility[0].realestate_id
            });
            await this.$store.dispatch("areas/getAreas", {
                filter: {
                    ...(this.realestate.getValue(true) ? { realestate_id: this.realestate.getValue(true) } : {}),
                },
            })
            console.log(this.currentRealEstate);
            document.getElementById('realestate_name').value = this.currentRealEstate[0].name;
            document.getElementById('realestate_address').value = this.currentRealEstate[0].zip + ' ' + this.currentRealEstate[0].city + ' ' +  this.currentRealEstate[0].address;
            this.meter.pod = parseInt(this.realestate.getValue(true));
        }

        if (document.getElementById("choices-munits")) {
            var munits = document.getElementById("choices-munits");
            this.munits = new Choices(munits);
            this.meter.measurement_units_id = parseInt(this.munits.getValue(true));
        }

        if (document.getElementById("choices-position")) {
            var position = document.getElementById("choices-position");
            this.position = new Choices(position);
            this.meter.position = String(this.position.getValue(true));
        }
    },
    
    methods: {
        async handleAdd() {
            this.resetApiValidation();
            try {
                this.meter.currentDate = this.currentDate;
                await this.$store.dispatch("meters/addMeter", this.meter);
                showSwal.methods.showSwal({
                    type: "success",
                    message: "Főmérő sikeresen hozzáadva!",
                    width: 500
                });
                this.$router.push({ name: "Meters List" })
            } catch (error) {
                if (error.response.data.errors) {
                    this.setApiValidation(error.response.data.errors);
                }
                showSwal.methods.showSwal({
                    type: "error",
                    message: "Valami hiba történt!",
                    width: 500
                });

            }
        },
        async setRealEstate() {
            const selectedUtility = this.UtilitiesList.filter(value => {
                return value.id === this.realestate.getValue(true)
            });
            this.currentRealEstate = this.getRealEstateList.filter(value => {
                return value.id == selectedUtility[0].realestate_id
            });
            document.getElementById('realestate_address').value = this.currentRealEstate[0].name;
        },
        setChoice() {
            this.areas = this.AreaList.map((item) => ({
                value: item.id, 
                label: item.area_nr,
            }));
        },
    }
}
</script>